
import { mapGetters, mapState } from 'vuex'
export default {
    name: 'Index',
    computed: {
        ...mapGetters({
            getSequenceAbleComponent: 'cms/getSequenceAbleComponent',
        }),
        ...mapState({
            floatingIcons: (state) => state.cms.floatingIcons,
            cms: (state) => state.cms.cms,
            isHomeComponentLoaded: (state) => state.cms.isHomeComponentLoaded,
            isFooterComponentLoaded: (state) =>
                state.cms.isFooterComponentLoaded,
        }),
        sequenceComponentCmsForDesktop() {
            return [
                {
                    name: 'Theme3DesktopHomeSlider',
                    code: 'SliderContentDesktop',
                },
                {
                    name: 'Theme3DesktopFeaturedContent1',
                    code: 'FeaturedContent1Desktop',
                },
                {
                    name: 'Theme3DesktopPromotionContent',
                    code: 'PromotionContentDesktop',
                },
                {
                    name: 'Theme3DesktopFeaturedContent2',
                    code: 'FeaturedContent2Desktop',
                },
            ]
        },
        dynamicSequenceComponentForDesktop() {
            const findMatchComponent = (componentCode) => {
                return this.getSequenceAbleComponent('desktop')?.find(
                    (el) => el.component_code === componentCode
                )?.sequence_desktop
            }
            return this.sequenceComponentCmsForDesktop
                .map((el) => {
                    return {
                        ...el,
                        sequence: findMatchComponent(el.code),
                    }
                })
                .sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence))
        },
    },
    mounted() {
        // Call this store action only when the user is on the home page
        if (this.isMobileOnly() && !this.isHomeComponentLoaded) {
            this.$store.dispatch('cms/homeCMS', { platform: 2 })
        }
        if (this.isMobileOnly() && !this.isFooterComponentLoaded) {
            this.$store.dispatch('cms/footerCMS', { platform: 2 })
        }
        // desktop
        if (!this.isMobileOnly() && !this.isHomeComponentLoaded) {
            this.$store.dispatch('cms/homeCMS', { platform: 1 })
        }
        if (!this.isMobileOnly() && !this.isFooterComponentLoaded) {
            this.$store.dispatch('cms/footerCMS', { platform: 1 })
        }
    },
}
